import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { HelloBar } from 'components/store/HelloBar';
import styles from './TopBarSalvia.module.css';
import { useQueryParams } from 'hooks/location';
import CustomShopChat from 'components/shop/CustomShopChat';
import StoreHeaderSalvia from './StoreHeaderSalvia';

export default function TopBarSalvia({
  shop,
  headerVariant,
  showChat
}) {

  const topBarRef = useRef();
  const params = useQueryParams();
  const chatOpenEnabled = params.has('openChat');

  return (
    <>
      <div ref={topBarRef} className={styles.topBar}>
        <HelloBar shop={shop}/>
        <div className={styles.topBarContainer}>
          <StoreHeaderSalvia
            shop={shop}
            showCart={headerVariant === 'normal'}
            showSearch={headerVariant === 'normal'}
            showChat={headerVariant === 'normal'}
          />
        </div>
      </div>
      <div id="topBarEnd"/>
      <div
        style={{ paddingTop: `${topBarRef?.current?.scrollHeight}px` }}
        className={styles.overflow}
      >
      </div>
      {showChat && chatOpenEnabled && (
        <CustomShopChat
          shopId={shop?._id}
          shop={{ name: shop?.name, }}
          slug={shop?.slug}
        />
      )}
    </>
  );

}

TopBarSalvia.propTypes = {
  shop: PropTypes.object.isRequired,
  headerVariant: PropTypes.string,
  showStepper: PropTypes.bool,
  isDigitalShipping: PropTypes.bool
};

TopBarSalvia.defaultProps = {
  catalogs: [],
  showTabs: true,
  headerVariant: 'normal',
  showStepper: false,
  isDigitalShipping: false,
  showSearch: true,
  showCart: true
};
