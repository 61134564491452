import React, { useState } from 'react';
import cx from 'classnames';
import { Button } from '@mui/material';
import Menu from 'components/common/MenuBar';
import { Clickable } from 'phoenix-components';
import { salviaHamIcon, salviaCartIcon, salviaSearchIcon, hamMobileIcon } from 'components/shared/Icons/Header';
import { useRouter } from 'next/router';
import { useIsCustomDomain, useIsVisitor, useShowLogin } from 'contexts/profile';
import { useQueryParams } from 'hooks/location';
import CustomShopChat from 'components/shop/CustomShopChat';
import { useLanguageLoader } from 'contexts/language';
import { getUserIcon } from 'components/utils/icon';
import { useIsDesktop } from 'contexts/profile';
import styles from './HeaderSalvia.module.css';
import { useAutoFontSize } from 'hooks/styles';
import { useThemeName } from 'hooks/shop';
import { useLocalCart } from 'contexts/cart';
import ShopUtils from 'ui/lib/shopUtils';
import Alert from 'components/alert/Alert';
import _ from 'ui/lodash'

function HeaderSalvia({
  // showProfile,
  myOrders,
  showSearch,
  showMenu,
  showBack,
  onBack,
  showLogout,
  showCart,
  payload,
  showShop,
  dataId,
  text,
  headerStyles,
  label,
  showChat,
  shop,
  isLandingPage,
}) {

  const [modal, showModal] = useState(false);

  const router = useRouter();
  const isVisitor = useIsVisitor();
  const showLogin = useShowLogin(shop);
  const lang = useLanguageLoader();
  const isDesktop = useIsDesktop();
  // const id = useProfileId();
  const params = useQueryParams();
  const menu = params.has('openMenu');
  // const showLogoutMenu = params.has('showLogout');
  const cartData = useLocalCart();
  const productsCount = _.sumBy(cartData?.products || [], 'quantity');
  const isCustomDomain = useIsCustomDomain();
  const themeName = useThemeName(shop);
  const ref = useAutoFontSize(themeName, label, isDesktop ? 22 : 18);
  const regex = /(<([^>]+)>)/ig;
  const isFilledAboutUs = !!shop?.about?.replace(regex, '').length > 0;
  const aboutUsEnabled = ShopUtils.isSectionEnabled(shop, 'aboutUs');


  const onClose = () => {

    params.delete('openMenu');
    router.push({ search: params.toString() }, undefined, { shallow: true });

  };

  const onBackAction = () => {

    if (showShop && !showBack) {

      const url = isCustomDomain ? '/' : payload?.shop?.pathname;
      if (url) {

        router.replace(url);
        return;

      }
      router.push({ pathname: '/' });
      return;

    }
    if (onBack) {

      onBack();
      return;

    }
    router.back();

  };

  const shopIcon = payload?.shop?.icon;
  const emptyShopIcon = '/images/store/back.svg';

  const feedTitleColl = ShopUtils.getTitle(shop, 'collections');
  const savedTitleColl = lang('store.headings.collections') === feedTitleColl ?
    lang('store.headings.collections') : feedTitleColl;

  const feedTitleAbout = ShopUtils.getTitle(shop, 'aboutUs');
  const savedTitleAbout = lang('store.menu.aboutUs') === feedTitleAbout ?
    lang('store.menu.aboutUs') : feedTitleAbout;

  return (
    <div className={cx('flexBetween', headerStyles)}>
      <div className={cx(styles.bigName, 'flexBetween fullWidth')}>
        {!isLandingPage && showShop && (
          <div className={styles.shop}>
            <div className={cx({ [styles.default]: !shopIcon && !showBack })}>
              <img
                src={showBack && !myOrders ? '/images/feed/backButton.svg'
                  : myOrders ? null : shopIcon || emptyShopIcon}
                alt=""
                data-id={dataId}
                className={cx({
                  [styles.shopIcon]: !showBack,
                  invert: !shopIcon && !showBack,
                  [styles.emptyIcon]: !shopIcon && !showBack
                })}
                data-value="shop button"
                role="none"
                onClick={!myOrders ? onBackAction : null}/>
            </div>
            <div
              role="none"
              data-id={dataId}
              ref={ref}
              data-value="shop button"
              onClick={!myOrders ? onBackAction : null}
              className={cx(styles.right)}>
              {label}
            </div>
          </div>
        )}
        {isLandingPage && (
          <img className={styles.landingPageLogo} src="/images/logo_png.png" alt="" />
        )}
        {isDesktop && (
          <div className={styles.headerLinks}>
            <div
              className={styles.headerText}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await router.push({ pathname: isCustomDomain ? '/' : `/${shop?.slug}` });
              }}
              data-id={dataId}>
              {lang('store.menu.home')}
            </div>
            {' '}
            <div
              className={styles.headerText}
              onClick={() => {
                router.push(isCustomDomain ? '/catalogList' : `/${shop?.slug}/catalogList`);
              }}>
              {savedTitleColl}
            </div>
            {' '}
            {aboutUsEnabled && isFilledAboutUs &&  (
              <div
                className={styles.headerText}
                onClick={() => showModal(true)}
                id="shopAboutUs"
              >
                {savedTitleAbout}
              </div>
            )}
            {modal && (
              <Alert
                onClose={() => showModal(false)}
                text={shop?.about?.replace(regex, '')}
              />
            )}
          </div>
        )}
        <div className={styles.flex}>
          {text && (
            <>
              {' '}
              {text}
              {' '}
            </>
          )}
          {isDesktop ? (
            <>
              {showSearch && (
                <span
                  role="presentation"
                  className={cx(styles.newIcon)}
                  data-id={dataId}
                  data-value="search button"
                  onClick={() => {
                    router.push({
                      pathname: isCustomDomain
                        ? '/search' : `/${shop?.slug}/search`,
                      search: params.toString()
                    });

                  }}
                >
                  {salviaSearchIcon()}
                </span>
              )}
              {showChat && (
                <CustomShopChat
                  shopId={shop?._id}
                  shop={{
                    ...shop,
                    name: shop?.name,
                    url: getUserIcon(shop)
                  }}
                  slug={shop?.slug}
                />
              )}
              {showMenu && (
                <Clickable
                  className={cx(styles.newIcon)}
                  onClick={() => {

                    params.set('openMenu', 'true');
                    router.push({
                      search: params.toString(),
                    }, undefined, { shallow: true });

                  }}
                >
                  {salviaHamIcon()}
                </Clickable>
              )}
              {showCart && (
                <div
                  className={cx(styles.newIcon, styles.relative)}
                  role="none"
                  onClick={() => {

                    if (isVisitor) {
                      showLogin(shop);
                      return;
                    }

                    const url = isCustomDomain ? '/cart' : `/${(shop?.slug || payload?.shop?.name)}/cart`;
                    router.push(url);
                  }}
                >
                  {salviaCartIcon()}
                  {productsCount > 0 && <span className={styles.count}>{productsCount}</span>}
                </div>
              )}
            </>
          ) : (
            <>
              {showCart && (
                <div
                  className={cx(styles.newIcon, styles.relative)}
                  role="none"
                  onClick={() => {

                    if (isVisitor) {
                      showLogin(shop);
                      return;
                    }

                    const url = isCustomDomain ? '/cart' : `/${(shop?.slug || payload?.shop?.name)}/cart`;
                    router.push(url);
                  }}
                >
                  {salviaCartIcon()}
                  {productsCount > 0 && <span className={styles.count}>{productsCount}</span>}
                </div>
              )}
              {showChat && (
                <CustomShopChat
                  shopId={shop?._id}
                  shop={{
                    ...shop,
                    name: shop?.name,
                    url: getUserIcon(shop)
                  }}
                  slug={shop?.slug}
                />
              )}
              {showSearch && (
                <span
                  role="presentation"
                  className={cx(styles.newIcon)}
                  data-id={dataId}
                  data-value="search button"
                  onClick={() => {
                    router.push({
                      pathname: isCustomDomain
                        ? '/search' : `/${shop?.slug}/search`,
                      search: params.toString()
                    });

                  }}
                >
                  {salviaSearchIcon()}
                </span>
              )}
              {showMenu && (
                <Clickable
                  className={cx(styles.newIcon)}
                  onClick={() => {

                    params.set('openMenu', 'true');
                    router.push({
                      search: params.toString(),
                    }, undefined, { shallow: true });

                  }}
                >
                  {hamMobileIcon()}
                </Clickable>
              )}
            </>
          )}
          {showLogout && (
            <Button
              color="secondary"
              className={styles.showSearch}
              onClick={() => {

                params.set('showLogout', 'true');
                router.push({
                  search: params.toString(),
                });

              }}>
              <img src="/images/profile/more.svg" alt="" />
            </Button>
          )}
        </div>
      </div>
      {menu && <Menu shop={shop} type="right" onClose={onClose} />}
    </div>
  );

}

HeaderSalvia.defaultProps = {
  // showProfile: false,
  myOrders: false,
  showSearch: false,
  showMenu: false,
  showBack: false,
  text: '',
  showLogout: false,
  showShop: false,
  showCart: false,
  payload: {},
  dataId: '',
  headerStyles: '',
  label: '',
  onBack: null,
  showChat: false,
  shop: {},
  isLandingPage: false,
  about: '',
};

export default HeaderSalvia;
